import * as React from "react";
import fiverrIcon from "../../assets/images/fiverr logo.png";
import {
  Avatar,
  Grid,
  Typography,
  Box,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  LinkedIn as LinkedInIcon,
  GitHub as GitHubIcon,
  Link as LinkIcon,
  DownloadForOffline as DownloadIcon,
  Mail as EmailIcon,
} from "@mui/icons-material";
import portraitImg from "../../assets/images/Chaofan_Wu.png";
import ScrollToSlide from "../../animation/slide/scrollToSlide";
import ScrollToFadeIn from "../../animation/fade/ScrollToFadeIn";
import { ReactComponent as RotatingBkg } from "../../assets/images/rotating-line-bkg.svg";
import RotatingImage from "../../animation/rotatingImg/scrollToRotate";
import { useMediaQuery } from "@mui/material";
import educations from "./experiences.json";
import RoleSwitcher from "../../components/roleSwitcher";

const Profile = ({ role, setRole }) => {
  const [titleText, setTitleText] = React.useState("chaofan.w@gmail.com");
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  console.log(role);

  const handleDownload = () => {
    const resumePath =
      role === "Dev"
        ? "/resume/Chaofan_Wu Resume.pdf"
        : "/resume/Chaofan_Wu Resume_mktg.pdf";
    const link = document.createElement("a");
    link.href = resumePath;
    link.setAttribute(
      "download",
      `${
        role === "Dev" ? "Chaofan_Wu Resume.pdf" : "Chaofan_Wu Resume_mktg.pdf"
      }`
    );
    link.click();
  };

  return (
    <Grid
      container
      component={"main"}
      sx={{
        // border: "1px solid red",
        // minHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        bgcolor: "primary.dark",
        mt: { xs: 3, sm: 5 },
      }}
    >
      <Grid
        item
        xs={12}
        container
        sx={{
          // border: "1px solid red",
          flexGrow: 1,
          // minHeight: "90vh",
          minHeight: { xs: "50vh", md: "min(90vh, 690px)" },
          height: "fit-content",
          display: "flex",
          alignItems: "flex-start",
          px: { xs: 0, sm: 2, md: 10 },
          py: { xs: 1, sm: 2, md: 5 },
        }}
      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: { xs: "row", md: "column" },
            alignItems: { xs: "center", md: "flex-start" },
            justifyContent: { xs: "space-between", md: "space-between" },
            // border: "1px solid blue",
            height: { xs: "fit-content" },
            minHeight: { md: "50vh" },
            position: "relative",
            px: { xs: 1, sm: 2, md: 3 },
            borderRight: { xs: "none", md: "2px solid #ffffff80" },
          }}
        >
          <Box
            sx={{
              height: 450,
              weight: 450,
              opacity: 1,
              position: "absolute",
              top: "-50%",
              left: "20%",
            }}
          >
            <RotatingImage>
              <RotatingBkg
                style={{
                  height: 450,
                  weight: 450,
                  opacity: 0.2,
                }}
              />
            </RotatingImage>
          </Box>
          <ScrollToFadeIn>
            <Avatar
              src={portraitImg}
              sx={{
                width: { xs: 100, sm: 130, md: 160 },
                height: { xs: 100, sm: 130, md: 160 },
              }}
            />
          </ScrollToFadeIn>
          <Stack
            direction="column"
            alignItems={"flex-start"}
            alignSelf={{ sm: "flex-start" }}
          >
            <Typography variant="h6" color="secondary">
              Contact:
            </Typography>

            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ alignSelf: "flex-start" }}
            >
              <Tooltip title="www.linkedin.com/in/chaofanwu" arrow>
                <IconButton
                  component="a"
                  href="https://www.linkedin.com/in/chaofanwu/"
                  target="_blank"
                >
                  <LinkedInIcon color="secondary" />
                </IconButton>
              </Tooltip>

              <Tooltip title="https://github.com/chaofan-w" arrow>
                <IconButton
                  component="a"
                  href="https://github.com/chaofan-w"
                  target="_blank"
                >
                  <GitHubIcon color="secondary" />
                </IconButton>
              </Tooltip>
              <Tooltip title={titleText} arrow>
                <IconButton
                  onMouseEnter={() => setTitleText("chaofan.w@gmail.com")}
                  onClick={async () => {
                    await navigator.clipboard.writeText("chaofan.w@gmail.com");
                    await setTitleText("email address copied!");
                  }}
                >
                  <EmailIcon color="secondary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="https://www.fiverr.com/s/3mbAoV" arrow>
                <IconButton
                  component="a"
                  href="https://www.fiverr.com/s/3mbAoV"
                  target="_blank"
                >
                  <img
                    src={fiverrIcon}
                    alt="fiverrlogo"
                    bgcolor="secondary"
                    style={{
                      width: "15px",
                      filter: "invert(1) hue-rotate(180deg)",
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title="download resume" arrow>
                <IconButton onClick={handleDownload}>
                  <DownloadIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            // border: "1px solid green",
            px: { xs: 1, sm: 2, md: 3 },
            height: { xs: "fit-content" },
          }}
        >
          <ScrollToSlide direction={"left"}>
            <Typography
              color={"secondary"}
              variant={isMobileScreen ? "h6" : "h6"}
              fontWeight={300}
              lineHeight={1.3}
              sx={{ mb: 3, width: "clamp(25ch, 80%, 75ch)" }}
            >
              {/* Welcome to my site! I am Chaofan, a dedicated junior full-stack
              web developer striving to build apps loved by people and so I can
              be proud of. */}
              Hello! I'm Chaofan. As a versatile professional, I wear two hats
              with proficiency:{" "}
              <span style={{ color: "red" }}>a full-stack web developer </span>
              and <span style={{ color: "red" }}>a marketing specialist.</span>
            </Typography>
            <Typography
              color={"secondary"}
              variant={isMobileScreen ? "h6" : "h6"}
              fontWeight={300}
              lineHeight={1.3}
              sx={{ my: 3, width: "clamp(25ch, 80%, 75ch)" }}
            >
              {/* Before I shifted my career path to web development, I spent over
              15 years working in branding and advertising and honed my skills
              of real-world problem-solving, project management, creative
              ideation, and communication. These skills have been instrumental
              in my career transition. */}
              With over 15 years immersed in the world of marketing, I've
              sharpened my abilities in creative ideation, tangible
              problem-solving, and impactful communication. Parallel to this, my
              venture into web development is fueled by an unwavering passion to
              construct applications that prioritize user experience, enhancing
              both business efficiency and communication. This distinctive blend
              positions me to tackle projects with a holistic perspective,
              guaranteeing that technical prowess aligns seamlessly with market
              insights.
            </Typography>
            {/* <Typography
              color={"secondary"}
              variant={isMobileScreen ? "h6" : "h6"}
              fontWeight={300}
              lineHeight={1.3}
              sx={{ my: 3, width: "clamp(25ch, 80%, 75ch)" }}
            >
              Switch between <span style={{ color: "red" }}>Dev</span> and{" "}
              <span style={{ color: "red" }}>Mktg</span> to explore more about
              me.
            </Typography> */}
            <Typography
              color={"secondary"}
              variant={isMobileScreen ? "h6" : "h6"}
              fontWeight={300}
              lineHeight={1.3}
              sx={{ my: 3, width: "clamp(25ch, 80%, 75ch)" }}
            >
              <span style={{ marginRight: "1em" }}>Switch between </span>
              <Stack
                display={"inline-flex"}
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                width={"fit-content"}
              >
                <Typography
                  color={role === "Dev" ? "white" : "grey"}
                  fontFamily={"Abril Fatface, cursive"}
                >
                  Dev
                </Typography>
                <RoleSwitcher role={role} setRole={setRole} />
                <Typography
                  color={role === "Mktg" ? "white" : "grey"}
                  fontFamily={"Abril Fatface, cursive"}
                >
                  Mktg
                </Typography>
              </Stack>
              <span style={{ marginLeft: "1em" }}>
                to explore more about me.
              </span>
            </Typography>
          </ScrollToSlide>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ height: "fit-content", px: { xs: 1, sm: 2, md: 3 }, py: 3 }}
        >
          <Grid item xs={12}>
            <Typography variant="h6" color="secondary">
              Educations:
            </Typography>
          </Grid>
          {educations &&
            educations.map((education, index) => (
              <Grid
                container
                sx={{ width: "100%", mx: "auto" }}
                key={`education-${index}`}
              >
                <Grid
                  item
                  xs={4}
                  sx={{
                    height: "fit-content",
                    px: { xs: 0, md: 2 },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <ScrollToSlide direction={"right"}>
                    <Typography
                      variant="body2"
                      color="secondary"
                      textAlign={"right"}
                      sx={{
                        fontFamily: "Noto Sans",
                        fontWeight: 200,
                        fontWrap: "wrap",
                      }}
                    >
                      {education.location}
                    </Typography>
                  </ScrollToSlide>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    height: "fit-content",
                    px: { xs: 0, md: 2 },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <ScrollToSlide direction={"left"}>
                    <Typography
                      variant="body2"
                      color="secondary"
                      sx={{
                        fontFamily: "Noto Sans",
                        fontWeight: 200,
                        fontWrap: "wrap",
                      }}
                    >
                      {education.activity}
                      {education.url && (
                        <a
                          href={`${education.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Tooltip title="to verify site">
                            <IconButton sx={{ height: 10 }}>
                              <LinkIcon
                                color="secondary"
                                sx={{ height: 20, width: 20 }}
                              />
                            </IconButton>
                          </Tooltip>
                        </a>
                      )}
                    </Typography>
                  </ScrollToSlide>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    height: "fit-content",
                    px: { xs: 0, md: 2 },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <ScrollToSlide direction={"left"}>
                    <Typography
                      variant="body2"
                      color="secondary"
                      sx={{
                        fontFamily: "Noto Sans",
                        fontWeight: 200,
                        fontWrap: "wrap",
                      }}
                    >
                      {education.time}
                    </Typography>
                  </ScrollToSlide>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;
