import * as React from "react";
import { styled } from "@mui/material/styles";

import Switch from "@mui/material/Switch";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        //   "#fff"
        // )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 25 25"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M12 3.5C7.22162 3.5 3.5 6.74642 3.5 10.5789C3.5 12.2394 4.18809 13.7765 5.36053 14.9966C5.8954 15.5532 6.53069 16.0431 7.24552 16.4461C7.48608 16.5817 7.57117 16.8866 7.43557 17.1272C7.29997 17.3677 6.99503 17.4528 6.75448 17.3172C5.95876 16.8687 5.24507 16.3197 4.63947 15.6895C3.31031 14.3062 2.5 12.5282 2.5 10.5789C2.5 6.04 6.83726 2.5 12 2.5C17.1627 2.5 21.5 6.04 21.5 10.5789C21.5 15.0787 17.2373 18.5967 12.1336 18.6571L7.24708 21.4347C7.00701 21.5711 6.70178 21.4872 6.56532 21.2471C6.42886 21.007 6.51285 20.7018 6.75292 20.5653L11.7529 17.7232C11.8282 17.6804 11.9134 17.6579 12 17.6579C16.7784 17.6579 20.5 14.4115 20.5 10.5789C20.5 6.74642 16.7784 3.5 12 3.5Z" /></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#bf172b",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 25 25"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M1.293,11.293l4-4A1,1,0,1,1,6.707,8.707L3.414,12l3.293,3.293a1,1,0,1,1-1.414,1.414l-4-4A1,1,0,0,1,1.293,11.293Zm17.414-4a1,1,0,1,0-1.414,1.414L20.586,12l-3.293,3.293a1,1,0,1,0,1.414,1.414l4-4a1,1,0,0,0,0-1.414ZM13.039,4.726l-4,14a1,1,0,0,0,.686,1.236A1.053,1.053,0,0,0,10,20a1,1,0,0,0,.961-.726l4-14a1,1,0,1,0-1.922-.548Z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

export default function RoleSwitcher({ role, setRole }) {
  // const navigate = useNavigate();
  const handleChange = async (event) => {
    await setRole(event.target.checked ? "Mktg" : "Dev");
    // navigate(`${event.target.checked ? "/mktg" : "/dev"}`);
  };

  return (
    <MaterialUISwitch
      sx={{ m: 1 }}
      checked={role === "Mktg"}
      onChange={handleChange}
    />
  );
}
