import * as React from "react";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography, Fade } from "@mui/material";
import { bgcolor } from "@mui/system";

export default function ScrollToFadeIn({ children }) {
  const [isVisible, setIsVisible] = React.useState(true);
  const domRef = React.useRef();

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setIsVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <Box
      sx={{
        height: "fit-content",
        width: "fit-content",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        p: 0,
      }}
      style={{
        transform: isVisible ? "none" : "translateY(5vh)",
        opacity: isVisible ? 1 : 0,
        visibility: isVisible ? "visible" : "hidden",
        transition: "opacity 0.6s ease-out, transform 1.2s ease-out",
      }}
      ref={domRef}
    >
      {/* <Paper elevation={3} sx={{ m: 1, bgcolor: "secondary.dark" }}> */}
      {children}
      {/* </Paper> */}
    </Box>
  );
}
