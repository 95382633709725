import * as React from "react";
import { Box, Typography } from "@mui/material";

export default function ScrollSlideFont(props) {
  const [isVisible, setIsVisible] = React.useState(true);
  const domRef = React.useRef();
  const items = React.Children.toArray(props.children);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setIsVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <Box ref={domRef} sx={{ height: 4 * props.height + "px" }}>
      {items.map((item, index) => (
        <Box
          key={`item-${index}`}
          sx={{
            width: "100%",
            height: isVisible ? props.height : 0,
            lineHeight: props.height + "px",
            willChange: "padding-left, opacity, height",
            overflow: "hidden",
            // transform: isVisible ? "translateX(10)" : "translateX(50)",
            pl: isVisible ? 0 : 5,
            opacity: isVisible ? 1 : 0,
            transition: `padding-left 0.8s ease-in-out ${
              0.1 * index
            }s,height 1s ease-out ${0.1 * index}s,opacity 1.2s ease-out ${
              0.1 * index
            }s`,
          }}
        >
          {item}
        </Box>
      ))}
    </Box>
  );
}
