import { Typography, Stack } from "@mui/material";
import * as React from "react";
import WavyText from "../animation/wavyText/wavyText";

const ChaofanIcon = () => {
  return (
    <Stack
      direction="column"
      alignItems={{ xs: "flex-end", md: "flex-start" }}
      sx={{ display: "flex", gap: 0 }}
    >
      <Typography
        sx={{
          fontFamily: "'Abril Fatface', cursive",
          p: 0,
        }}
      >
        Chaofan Wu
      </Typography>

      <Typography
        sx={{
          m: 0,
          p: 0,
          display: "flex",
          alignItems: "flex-start",
          fontFamily: "Noto Sans",
          fontWeight: 100,
          fontSize: `11px`,
        }}
      >
        {<WavyText wavyString={"portfolio2024"} />}
      </Typography>
    </Stack>
  );
};

export default ChaofanIcon;
