import React from "react";
import { Typography, Grid, Stack, Divider } from "@mui/material";
import SkillTitleCompo from "./skillTItle";

import ScrollingText from "../../animation/scrollingText/scrollingTextBanner";
import skillData from "./skills.json";
import mktgSkillData from "./mktg-skills.json";

const Skills = ({ role }) => {
  return (
    <Grid
      container
      component="main"
      sx={{ m: 0, p: 0, width: "100%", maxWidth: "100vw" }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h3"
          color={"secondary"}
          sx={{
            fontFamily: "'Abril Fatface', cursive",
            px: { xs: 1, sm: 2, md: 3 },
          }}
        >
          Skills
        </Typography>
      </Grid>
      {role === "Dev" ? (
        <React.Fragment>
          <Grid
            item
            xs={12}
            sx={{
              my: { xs: 4, md: 6 },
              height: 30,
              pr: { xs: 1, sm: 2, md: 3 },
            }}
          >
            <Typography
              variant="subtitle2"
              color="secondary"
              textAlign={"right"}
              sx={{ fontFamily: "Noto sans", fontWeight: 100 }}
            >
              Programming Languages
            </Typography>
            <ScrollingText speed={20} direction={"left"}>
              {skillData
                .filter((skill) => skill.category === "Programming Languages")
                .map((skill) => (
                  <SkillTitleCompo
                    key={skill.name}
                    title={skill.name}
                    value={skill.score}
                    color={skill.color}
                  />
                ))}
            </ScrollingText>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              my: { xs: 4, md: 6 },
              height: 30,
              // border: "1px solid red",

              pr: { xs: 1, sm: 2, md: 3 },
            }}
          >
            <Typography
              variant="subtitle2"
              color="secondary"
              textAlign={"right"}
              sx={{ fontFamily: "Noto sans", fontWeight: 100, mt: 3 }}
            >
              Libraries & Frameworks
            </Typography>
            <ScrollingText speed={20} direction={"right"}>
              {skillData
                .filter((skill) => skill.category === "Libraries & Frameworks")
                .map((skill) => (
                  <SkillTitleCompo
                    key={skill.name}
                    title={skill.name}
                    value={skill.score}
                    color={skill.color}
                  />
                ))}
            </ScrollingText>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              my: { xs: 4, md: 6 },
              height: 30,
              pr: { xs: 1, sm: 2, md: 3 },
            }}
          >
            <Typography
              variant="subtitle2"
              color="secondary"
              textAlign={"right"}
              sx={{ fontFamily: "Noto sans", fontWeight: 100, mt: 4 }}
            >
              Databases & Version Control
            </Typography>
            <ScrollingText speed={20} direction={"left"}>
              {skillData
                .filter(
                  (skill) => skill.category === "Database & Version Control"
                )
                .map((skill) => (
                  <SkillTitleCompo
                    key={skill.name}
                    title={skill.name}
                    value={skill.score}
                    color={skill.color}
                  />
                ))}
            </ScrollingText>
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid
            item
            xs={12}
            sx={{
              my: { xs: 4, md: 6 },
              height: 30,
              pr: { xs: 1, sm: 2, md: 3 },
            }}
          >
            <Typography
              variant="subtitle2"
              color="secondary"
              textAlign={"right"}
              sx={{ fontFamily: "Noto sans", fontWeight: 100 }}
            >
              Microsoft Office & Google Suite
            </Typography>
            <ScrollingText speed={20} direction={"left"}>
              {mktgSkillData
                .filter(
                  (skill) =>
                    skill.category === "Microsoft Office & Google Suite"
                )
                .map((skill) => (
                  <SkillTitleCompo
                    key={skill.name}
                    title={skill.name}
                    value={skill.score}
                    color={skill.color}
                  />
                ))}
            </ScrollingText>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              my: { xs: 4, md: 6 },
              height: 30,
              // border: "1px solid red",

              pr: { xs: 1, sm: 2, md: 3 },
            }}
          >
            <Typography
              variant="subtitle2"
              color="secondary"
              textAlign={"right"}
              sx={{ fontFamily: "Noto sans", fontWeight: 100, mt: 3 }}
            >
              Design & Multimedia Tools
            </Typography>
            <ScrollingText speed={20} direction={"right"}>
              {mktgSkillData
                .filter(
                  (skill) => skill.category === "Design & Multimedia Tools"
                )
                .map((skill) => (
                  <SkillTitleCompo
                    key={skill.name}
                    title={skill.name}
                    value={skill.score}
                    color={skill.color}
                  />
                ))}
            </ScrollingText>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              my: { xs: 4, md: 6 },
              height: 30,
              pr: { xs: 1, sm: 2, md: 3 },
            }}
          >
            <Typography
              variant="subtitle2"
              color="secondary"
              textAlign={"right"}
              sx={{
                fontFamily: "Noto sans",
                fontWeight: 100,
                mt: 4,
              }}
            >
              Porgramming & Web Dev
            </Typography>
            <ScrollingText speed={20} direction={"left"}>
              {mktgSkillData
                .filter((skill) => skill.category === "Porgramming & Web Dev")
                .map((skill) => (
                  <SkillTitleCompo
                    key={skill.name}
                    title={skill.name}
                    value={skill.score}
                    color={skill.color}
                  />
                ))}
            </ScrollingText>
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={12} sx={{ my: 5 }}>
        <Divider sx={{ my: 8, mx: "2vw", border: "0.5px solid grey" }} />
      </Grid>
    </Grid>
  );
};

export default Skills;
