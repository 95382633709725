import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";

function RotatingImage(props) {
  const [rotation, setRotation] = useState(0);
  const imageRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const image = imageRef.current;
    if (image) {
      const { top, bottom } = image.getBoundingClientRect();
      if (top >= 0 && bottom <= window.innerHeight) {
        // image is in view
        const scrollY = window.scrollY;
        // console.log(scrollY);
        const newRotation = (scrollY % 360) / 6;
        setRotation(newRotation);
      }
    }
  };

  // console.log(rotation);

  return (
    <Box
      sx={{
        height: "fit-content",
        width: "fit-content",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        p: 0,
        transform: `rotate(${rotation}deg)`,
        transition: "transform 1.5s ease-in-out",
      }}
      ref={imageRef}
    >
      {props.children}
    </Box>
  );
}

export default RotatingImage;
