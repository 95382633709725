import * as React from "react";

import { Box, Stack, Grid, Typography } from "@mui/material";
import CircularGauge from "../../components/circularGauge/CircularGauge";

const SkillTitleCompo = (props) => {
  // const containerRef = React.useRef();
  // const [width, setWidth] = React.useState(0);
  // const [height, setHeight] = React.useState(0);
  // console.log(`width: ${width}, height: ${height}`);

  // React.useEffect(() => {
  //   setWidth(containerRef.current.getBoundingClientRect().width);
  //   setHeight(containerRef.current.getBoundingClientRect().height);
  // }, [containerRef.current?.getBoundingClientRect().width]);

  return (
    <Box
      sx={{
        width: "fit-content",
        height: "100%",
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
      }}
    >
      <Typography
        color={props.color === "red" ? props.color : "secondary"}
        sx={{
          fontFamily: "'Abril Fatface', cursive",
          fontSize: { xs: "2.2rem", sm: "2.5rem", md: "4rem" },
        }}
      >
        {props.title}
      </Typography>
      <Box
        sx={{
          width: { xs: "2rem", sm: "2.5rem", md: "4rem" },
          height: { xs: "2rem", sm: "2.5rem", md: "4rem" },
          padding: 0,
        }}
        // ref={containerRef}
      >
        <CircularGauge
          value={props.value || 90}
          // containerRef={containerRef}
          // width={width}
          // height={height}
        />
      </Box>
    </Box>
  );
};

export default SkillTitleCompo;
