import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import ChaofanIcon from "./chaofanIcon";
import Stack from "@mui/material/Stack";
import ResumeDownloadBtn from "./resumeDownload";
import RoleSwitcher from "./roleSwitcher";

const sections = ["About", "Skills", "Works"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar({ refs, role, setRole }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleScrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleDownload = () => {
    const resumePath =
      role === "Dev"
        ? "/resume/Chaofan_Wu Resume.pdf"
        : "/resume/Chaofan_Wu Resume_mktg.pdf";
    const link = document.createElement("a");
    link.href = resumePath;
    link.setAttribute(
      "download",
      `${
        role === "Dev" ? "Chaofan_Wu Resume.pdf" : "Chaofan_Wu Resume_mktg.pdf"
      }`
    );
    link.click();
  };

  return (
    <AppBar
      sx={{
        position: "fixed",
        left: "50%",
        top: 0,
        transform: "translateX(-50%)",
        backgroundColor: "transparent",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "primary.dark",
          boxShadow:
            "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
        },
        width: "100vw",
        maxWidth: 1920,
        px: { xs: 1, sm: 2, md: 4 },
      }}
    >
      <Toolbar disableGutters>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "none" },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ChaofanIcon />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography
              color={role === "Dev" ? "white" : "grey"}
              fontFamily={"Abril Fatface, cursive"}
            >
              Dev
            </Typography>
            <RoleSwitcher role={role} setRole={setRole} />
            <Typography
              color={role === "Mktg" ? "white" : "grey"}
              fontFamily={"Abril Fatface, cursive"}
            >
              Mktg
            </Typography>
          </Stack>

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {sections.map((section) => (
              <MenuItem
                key={section}
                onClick={async () => {
                  await handleScrollTo(refs[section]);
                  setTimeout(() => handleCloseNavMenu(), 1000);
                  // handleCloseNavMenu();
                }}
              >
                <Typography textAlign="center">{section}</Typography>
              </MenuItem>
            ))}
            <MenuItem>
              <ResumeDownloadBtn
                color="primary"
                handleDownload={handleDownload}
              />
            </MenuItem>
          </Menu>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            // ml: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"start"}
            gap={5}
          >
            <ChaofanIcon />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Typography
                color={role === "Dev" ? "white" : "grey"}
                fontFamily={"Abril Fatface, cursive"}
              >
                Dev
              </Typography>
              <RoleSwitcher role={role} setRole={setRole} />
              <Typography
                color={role === "Mktg" ? "white" : "grey"}
                fontFamily={"Abril Fatface, cursive"}
              >
                Mktg
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems={"center"}>
            {sections.map((section) => (
              <Button
                key={section}
                onClick={() => handleScrollTo(refs[section])}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {section}
              </Button>
            ))}

            <ResumeDownloadBtn
              color={"secondary"}
              handleDownload={handleDownload}
            />
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default ResponsiveAppBar;
