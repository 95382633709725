import * as React from "react";
// import styles from "./styles.module.css";
import styled, { keyframes } from "styled-components";

const WavyText = (props) => {
  const { wavyString } = props;

  const letterSpans = wavyString.split("").map((char, index) => {
    return (
      <StyledSpan
        className={"wavy-text"}
        key={`${char}-${index}`}
        style={{
          animationDelay: `${index * 60 + 500}ms`,
        }}
      >
        {char}
      </StyledSpan>
    );
  });

  return <>{letterSpans && letterSpans.map((span, index) => span)}</>;
};

export default WavyText;

const wavy = keyframes`
  0% {
    top: 0;
  }
  5% {
    top: -1rem;
  }
  10% {
    top: 0;
  }
  100% {
    top: 0;
  }
`;

const StyledSpan = styled.span`
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  animation-name: ${wavy};
  animation-duration: 10s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  position: relative;
  top: 0;
  left: 0;
`;
