import React, { useState, useEffect, useRef } from "react";

// javascript to realize the continuous scrolling text effect

const ScrollingText = (props) => {
  const [textContainerWidth, setTextContainerWidth] = useState(
    parseInt(localStorage.getItem("textContainerWidth")) || 0
  );
  const [textWidth, setTextWidth] = useState(
    parseInt(localStorage.getItem("textWidth")) || 0
  );

  const textContainerRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    const setTextWidths = () => {
      setTextContainerWidth(
        textContainerRef.current.getBoundingClientRect().width
      );
      setTextWidth(textRef.current.getBoundingClientRect().width);
    };
    setTextWidths();
    window.addEventListener("resize", setTextWidths);
    return () => {
      window.removeEventListener("resize", setTextWidths);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("textContainerWidth", textContainerWidth);
    localStorage.setItem("textWidth", textWidth);
  }, [textContainerWidth, textWidth]);

  const [offset, setOffset] = useState(0);
  // console.log(offset);

  // console.log(
  //   `textContainerWidth:${textContainerWidth}, textWidth:${textWidth}`
  // );

  useEffect(() => {
    let intervalId = null;
    const startAnimation = () => {
      intervalId = setInterval(() => {
        setOffset((offset) => {
          const newOffset = offset + 1;

          if (textContainerWidth > textWidth) {
            if (newOffset >= 2 * textContainerWidth) {
              return newOffset - textContainerWidth - textContainerWidth;
            }
            return newOffset;
          } else {
            if (newOffset >= textContainerWidth + textWidth) {
              return newOffset - textContainerWidth - textWidth;
            }
            return newOffset;
          }
        });
      }, props.speed);
    };
    const stopAnimation = () => {
      clearInterval(intervalId);
    };
    startAnimation();

    return () => stopAnimation();
  }, [props.speed, offset, textContainerWidth, textWidth]);

  const offset2 = (offset) => {
    const newOffset = offset + 1;
    if (textContainerWidth > textWidth) {
      if (newOffset <= textWidth) {
        return newOffset + textContainerWidth;
      } else if (newOffset > textWidth && newOffset < 2 * textContainerWidth) {
        return newOffset - textContainerWidth;
      } else if (newOffset >= 2 * textContainerWidth) {
        return newOffset - textContainerWidth;
      }
    } else {
      if (newOffset <= textWidth + 50) {
        return newOffset + 50 + textWidth;
      } else if (
        newOffset > textWidth + 50 &&
        newOffset < textContainerWidth + textWidth
      ) {
        return newOffset - textWidth - 50;
      }
    }
  };

  return (
    <div
      className="scrolling-text-container"
      ref={textContainerRef}
      style={{
        position: "relative",
        width: "100%",
        maxWidth: "100vw",
        height: "fit-content",
        marginTop: "15px",
      }}
    >
      <div
        className="scrolling-text"
        style={{
          transform: `${
            props.direction === "left"
              ? "translateX(-" + offset + "px"
              : "translateX(" + offset + "px)"
          }`,
          width: "fit-content",
          // border: "1px solid blue",
          position: "absolute",
          height: "fit-content",
          right: `${
            props.direction === "left"
              ? "-" + textWidth + "px"
              : textWidth + "px"
          }`,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "5vw",
        }}
        ref={textRef}
      >
        {props.children}
      </div>
      <div
        className="scrolling-text"
        style={{
          // transform: `translateX(-${offset2(offset)}px)`,
          transform: `${
            props.direction === "left"
              ? "translateX(-" + offset2(offset) + "px"
              : "translateX(" + offset2(offset) + "px)"
          }`,
          width: "fit-content",
          // border: "1px solid blue",
          position: "absolute",
          height: "fit-content",
          // right: `-${textWidth}px`,
          right: `${
            props.direction === "left"
              ? "-" + textWidth + "px"
              : textWidth + "px"
          }`,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "5vw",
        }}
        // ref={textRef}
      >
        {props.children}
      </div>
    </div>
  );
};

export default ScrollingText;
