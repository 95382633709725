import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto'
  }

  html, body {
    max-width: 100vw;
    background-color: #151516;
  }


  /* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  
  body {
    background-color: #f0f0f0;
    overflow:overlay
  }

/* Customize the appearance of the scrollbar */

@supports (overflow:overlay){
  body {
  --scrollbar-color: rgba(29,31,33,0.2);
  /* --display-bar:none; */
}

body.scrolling {
  --scrollbar-color: rgba(29,31,33,0.8) !important;
  /* --display-bar:block !important; */
}

  ::-webkit-scrollbar {
    display: block;
    /* display: var(--display-bar); */
    width: 8px;
    transition: background-color 2s ease-in-out 0.5s;
  }
  ::-webkit-scrollbar-button {
  display: none;
}
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
    /* background-color: rgb(254, 254, 254, 0.7); */
  }
  
  ::-webkit-scrollbar-track-piece {
    background-color: rgb(254, 254, 254, 0.7);
}
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 5px;
    border: 5px solid transparent;
    box-shadow: 4px 0px 0px 4px var(--scrollbar-color) inset;
  }
  
  /* Hide the scrollbar when not in use */
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color);
    /* background-color: rgba(29,31,33,0.8); */
    border: 0px solid transparent;
    box-shadow: none;
  }
}


`;
