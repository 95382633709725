import * as React from "react";
import { IconButton } from "@mui/material";
import { BiArrowToTop } from "react-icons/bi";

// https://articles.wesionary.team/handling-scrolls-in-react-e07e6d1fef82

const BackToTopBtn = () => {
  const [showBtn, setShowBtn] = React.useState(false);
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 500) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  };
  React.useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <React.Fragment>
      {showBtn && (
        <IconButton
          variant="contained"
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            zIndex: 1000,
            color: "secondary.main",
            "@media (min-width: 1920px)": {
              right: "calc(50vw - 940px)",
            },
          }}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <BiArrowToTop />
        </IconButton>
      )}
    </React.Fragment>
  );
};

export default BackToTopBtn;
