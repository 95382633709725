import { Box, Typography } from "@mui/material";
import React from "react";
import { RadialBarChart, RadialBar, Legend } from "recharts";
import ScrollingNumber from "../../animation/scrollingNumber/scrollingNumber";

const CircularGauge = ({ value, containerRef, width, height }) => {
  // const containerRef = React.useRef();

  const data = [
    {
      name: "value",
      value: value,
      fill: "#E3E3E3",
    },
    { name: null, value: 100, fill: "#798185" },
  ];
  return (
    <Box
      sx={{
        width: "inherit",
        height: "inherit",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // border: "1px solid #E3E3E3",
        padding: 0,
        marginTop: 1,
      }}
      // ref={containerRef}
    >
      <RadialBarChart
        width={70}
        height={70}
        innerRadius="60%"
        outerRadius="90%"
        data={data}
        startAngle={225}
        endAngle={-45}
        barGap={10}
        barCategoryGap={"10%"}
        margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
      >
        <RadialBar
          minAngle={15}
          clockWise={true}
          dataKey="value"
          cornerRadius={0}
          isAnimationActive={true}
          animationBegin={0}
          animationDuration={1000}
          animationEasing="ease-out"
          // label={{
          //   position: "center",
          //   fill: color,
          //   fontSize: "2.5rem",
          //   fontWeight: "bold",
          // }}
        />
      </RadialBarChart>
      <Typography
        variant="h6"
        color="secondary"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <ScrollingNumber value={value} />
      </Typography>
    </Box>
  );
};

export default CircularGauge;
