import { useEffect, useState } from "react";

const ScrollingNumber = ({ value }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    let animationFrame;
    const step = () => {
      const diff = value - currentValue;
      const increment = Math.ceil(diff / 10);
      const nextValue = currentValue + increment;

      if (nextValue >= value) {
        setCurrentValue(value);
      } else {
        setCurrentValue(nextValue);
        animationFrame = requestAnimationFrame(step);
      }
    };

    animationFrame = requestAnimationFrame(step);

    return () => cancelAnimationFrame(animationFrame);
  }, [currentValue, value]);

  return (
    <div className="scrolling-number">{currentValue.toLocaleString()}</div>
  );
};

export default ScrollingNumber;
