import * as React from "react";
import { Grid, Button, Box, Typography } from "@mui/material";
import { DownloadRounded } from "@mui/icons-material";
import GrowBox from "../animation/grow/growBox";
import { gsap } from "gsap";

const ResumeDownloadBtn = ({ color, handleDownload }) => {
  const [checked, setChecked] = React.useState(false);

  const comp = React.useRef();
  React.useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(
      comp.current,
      {
        rotate: 0,
        scale: 0,
      },
      {
        rotate: 360,
        scale: 1,
        duration: 1,
        ease: "power2.out",
        repeat: 2,
        repeatDelay: 0.5,
      }
    );
    return () => {
      tl.kill();
    };
  }, []);

  const handleMouseEnter = () => {
    setChecked(true);
  };
  const handleMouseLeave = () => {
    setChecked(false);
  };

  return (
    <Button
      onClick={handleDownload}
      variant="outlined"
      color={color}
      sx={{
        width: 80,
        height: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 0,
        m: 0,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Typography
        variant="body2"
        ref={comp}
        sx={{ fontFamily: "Oswald", fontWeight: 700, color: "red" }}
      >
        Resume
      </Typography>
      <GrowBox checked={checked}>
        <DownloadRounded sx={{ width: 20, height: 20, color: "red" }} />
      </GrowBox>
    </Button>
  );
};

export default ResumeDownloadBtn;
