import {
  Typography,
  Grid,
  Card,
  CardMedia,
  Button,
  dividerClasses,
  Box,
  CardContent,
} from "@mui/material";
import * as React from "react";
// import gadget_go_multi_device from "../../assets/images/Multi-Devices.png";
import worksData from "./works.json";
import mktgWorksData from "./mktg_works.json";
import ScrollToGrowBox from "../../animation/grow/scrollToGrow";
import MouseStalker from "../../animation/mouseStalker/mouseStalker";
import { GitHub, ArrowOutward } from "@mui/icons-material";
import HoverToSlideBtn from "../../components/hoverToSlideBtn";

const Works = ({ role }) => {
  const [works, setWorks] = React.useState([]);
  const gridRef = React.useRef(null);
  const [gridWidth, setGridWidth] = React.useState(0);
  const [gridHeight, setGridHeight] = React.useState(0);

  React.useEffect(() => {
    const importWorks = async () => {
      let data;
      if (role === "Dev") {
        data = worksData;
      } else {
        data = mktgWorksData;
      }

      const importedWorks = await Promise.all(
        data.map(async (work) => {
          const { imgUrl, ...rest } = work;
          const importedImg =
            (imgUrl && (await import(`../../assets/images/${imgUrl}`))) || null;
          return { imgUrl: importedImg ? importedImg.default : null, ...rest };
        })
      );
      setWorks(importedWorks);
    };

    importWorks();
  }, [role]);

  React.useEffect(() => {
    // Function to update the width
    const updateWidth = () => {
      if (gridRef.current) {
        setGridWidth(gridRef.current.offsetWidth);
        setGridHeight(gridRef.current.offsetHeight);
      }
    };

    // Initial width update
    const timeoutId = setTimeout(updateWidth, 500);

    // Add event listener for window resize
    window.addEventListener("resize", updateWidth);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWidth);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Grid
      container
      component={"main"}
      sx={{
        // border: "1px solid red",
        minHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        mt: { xs: 3, sm: 5 },
      }}
    >
      <Grid item xs={12} sx={{ minHeight: "10vh" }}>
        <Typography
          variant="h3"
          color={"secondary"}
          sx={{
            fontFamily: "'Abril Fatface', cursive",
            px: { xs: 1, sm: 2, md: 3 },
          }}
        >
          Works
        </Typography>
      </Grid>
      {works &&
        works.map((work, index) => {
          return (
            <Grid
              item
              xs={12}
              container
              sx={{ px: { xs: 1, sm: 8, md: 10 }, mt: 5 }}
              key={`work-${index}`}
              direction={index % 2 === 0 ? "row" : "row-reverse"}
            >
              <Grid item xs={12} md={5}>
                <Grid item xs={12} sx={{ mb: 5 }}>
                  <Typography variant="h4" color="secondary">
                    {work.title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    my: 3,
                    pb: 3,
                    borderBottom: `${
                      role === "Mktg" ? "none" : "1px solid grey"
                    }`,
                  }}
                >
                  {role === "Mktg" && (
                    <>
                      <Grid item xs={12} container>
                        <Grid item xs={3}>
                          <Typography variant="body2" color="secondary">
                            Company:
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography variant="body2" color="secondary">
                            {work.company}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container sx={{ my: 3 }}>
                        <Grid item xs={3}>
                          <Typography variant="body2" color="secondary">
                            Role:
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography variant="body2" color="secondary">
                            {work.role}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container sx={{ mb: 3 }}>
                        <Grid item xs={3}>
                          <Typography variant="body2" color="secondary">
                            Serve Peroid:
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography variant="body2" color="secondary">
                            {work.period}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={3}>
                    <Typography variant="body2" color="secondary">
                      {role === "Dev" ? "Built with:" : "Responsible:"}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="secondary">
                      {work.builtWith}
                    </Typography>
                  </Grid>
                  {role === "Mktg" && (
                    <Grid item xs={12} container sx={{ mt: 3 }}>
                      <Grid item xs={3}>
                        <Typography variant="body2" color="secondary">
                          Result:
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="body2" color="secondary">
                          {work.result}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  sx={{ my: 3, pb: 3, borderBottom: "1px solid grey" }}
                >
                  <Grid item xs={3}>
                    <Typography variant="body2" color="secondary">
                      Project Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" color="secondary">
                      {work.releaseDate}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    my: 3,
                    pb: 3,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {/* <Button
                    variant="outlined"
                    color="secondary"
                    component="a"
                    href={work.siteLink}
                    target="_blank"
                    sx={{ py: 0.25 }}
                  >
                    Live Demo
                  </Button> */}
                  {work.siteLink && (
                    <HoverToSlideBtn
                      text={role === "Dev" ? "Demo" : `${work.btn}`}
                      href={work.siteLink}
                      color={"secondary"}
                      icon={
                        <ArrowOutward
                          sx={{
                            width: 20,
                            height: 20,
                            color: "secondary",
                            ml: 1,
                          }}
                        />
                      }
                    />
                  )}

                  {role === "Dev" && (
                    <HoverToSlideBtn
                      text="Github"
                      href={work.href}
                      color={"secondary"}
                      icon={
                        <GitHub
                          sx={{
                            width: 20,
                            height: 20,
                            color: "secondary",
                            ml: 1,
                          }}
                        />
                      }
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item xs={12} md={6} ref={gridRef}>
                {/* <ScrollToGrowBox> */}
                <MouseStalker
                  hoverTarget={
                    <>
                      {(work.component === "img" ||
                        (work.component === "video" && work.imgUrl)) && (
                        <Card
                          onClick={() => {
                            if (work.component === "img") {
                              window.open(work.siteLink, "_blank");
                            }
                          }}
                          sx={{
                            width: { xs: "100%", sm: "100%", md: "100%" },
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          <CardMedia
                            component={work.component}
                            image={work.component === "img" ? work.imgUrl : ""}
                            src={work.component === "video" ? work.imgUrl : ""}
                            alt={work.title}
                            height="100%"
                            controls={work.component === "video" ? true : false}
                          />
                        </Card>
                      )}
                      {work.component === "video" && work.src && (
                        <iframe
                          title={work.title}
                          src={`${work.src}?title=0&byline=0&portrait=0`}
                          style={{
                            width: `${gridWidth}px`,
                            height: `${gridHeight}px`,
                            maxHeight: `${gridWidth * 0.5625}px`,
                          }}
                          allowFullScreen
                        ></iframe>
                      )}
                      {work.component === "presentation" && (
                        <iframe
                          title={work.title}
                          src={work.src}
                          style={{
                            width: `${gridWidth}px`,
                            height: `${gridHeight}px`,
                            maxHeight: `${gridWidth * 0.5625}px`,
                          }}
                          allowFullScreen
                        ></iframe>
                      )}
                    </>
                  }
                  btnText="View Case"
                />
                {/* </ScrollToGrowBox> */}
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default Works;
