import * as React from "react";
import { Box, Typography, Paper, Button, Stack, Card } from "@mui/material";

import ScrollSlideFont from "../animation/slideFont/scrollSlideFont";
import WavyText from "../animation/wavyText/wavyText";

const Headline = ({ role }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        maxWidth: "100%",
        px: { xs: 1, sm: 4 },
      }}
    >
      <ScrollSlideFont
        height={{ xs: "3.4rem", sm: "5.8rem", md: "8rem", lg: "9rem" }}
      >
        <Typography
          letterSpacing={4}
          color={"secondary.main"}
          sx={{
            fontFamily: "'Abril Fatface', cursive",
            fontSize: { xs: "2.2rem", sm: "4rem", md: "6rem", lg: "7rem" },
          }}
        >
          Hello.
        </Typography>
        <Typography
          color={"secondary.main"}
          sx={{
            fontFamily: "'Abril Fatface', cursive",
            fontSize: { xs: "2.2rem", sm: "4rem", md: "6rem", lg: "7rem" },
          }}
        >
          This is
          <Typography
            component={"span"}
            letterSpacing={4}
            color={"secondary.main"}
            sx={{
              fontFamily: "'Abril Fatface', cursive",
              fontSize: {
                xs: "2.2rem",
                sm: "4rem",
                md: "6rem",
                lg: "7rem",
              },
            }}
          >
            <WavyText wavyString={" Chaofan"} />,
          </Typography>
        </Typography>
        <Typography
          letterSpacing={4}
          color={"secondary.main"}
          sx={{
            fontFamily: "'Abril Fatface', cursive",
            fontSize: { xs: "2.2rem", sm: "4rem", md: "6rem", lg: "7rem" },
          }}
        >
          {role === "Dev" ? "a full-stack" : "a brand"}
        </Typography>
        <Typography
          letterSpacing={4}
          color={"secondary.main"}
          sx={{
            fontFamily: "'Abril Fatface', cursive",
            fontSize: { xs: "2.2rem", sm: "4rem", md: "6rem", lg: "7rem" },
          }}
        >
          {role === "Dev" ? "web developer." : "storyteller."}
        </Typography>
      </ScrollSlideFont>
    </Box>
  );
};

export default Headline;
