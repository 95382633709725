import * as React from "react";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography, Fade, Grow, Slide } from "@mui/material";
import { bgcolor } from "@mui/system";

export default function ScollToSlide(props) {
  const [isVisible, setIsVisible] = React.useState(true);

  const domRef = React.useRef();

  // https://dev.to/selbekk/how-to-fade-in-content-as-it-scrolls-into-view-10j4

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setIsVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    // we need to remove the intersection listener from our DOM node whenever we unmount it! Luckily, we can return a cleanup function from useEffect, which will do this for us.
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <Box
      ref={domRef}
      sx={{
        height: "fit-content",
        width: "fit-content",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        p: 0,
      }}
    >
      <Slide
        direction={props.direction}
        in={isVisible}
        easing={{ enter: "linear", exit: "ease-in-ease-out" }}
        timeout={{ enter: 800, exit: 500 }}
        // style={{ transitionDelay: isVisible ? "800ms" : "0ms" }}
        // unmountOnExit={true}: When the component is hidden, it is removed from the DOM completely. This means that the component is not in the DOM and does not take up any space. If you have a large number of hidden components or need to optimize rendering performance, unmountOnExit can be a useful tool. However, if your components are simple or frequently shown and hidden, it may not provide significant benefits and could even hurt performance.
        unmountOnExit
        sx={{
          height: "fit-content",
          width: "fit-content",
          maxWidth: "100%",
          maxHeight: "100%",
          p: 1,
        }}
      >
        <Box>{props.children}</Box>
      </Slide>
    </Box>
  );
}
