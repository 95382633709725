import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { Grid } from "@mui/material";
import DefaultStyles from "./globalStyle";
import BackToTopBtn from "./components/backToTopBtn";
import Headline from "./components/headline";
import ResponsiveAppBar from "./components/menuBar";
import Skills from "./sections/skills/skills";
import Works from "./sections/works/works";
import Profile from "./sections/profile/profile";

function App() {
  // scrollbar add a scrolling event listening, when scrolling, add classname 'scrolling' to body, and applied related css style
  const [role, setRole] = React.useState("Dev");

  let barTimeout;
  document.body.onscroll = () => {
    if (barTimeout) {
      clearTimeout(barTimeout); //clear to reset
    }
    barTimeout = setTimeout(() => {
      document.body.classList.remove("scrolling");
    }, 500); //0.5s delay
    document.body.classList.add("scrolling");
  };

  // use ref to position each section of the long page
  const aboutRef = React.useRef(null);
  const skillsRef = React.useRef(null);
  const worksRef = React.useRef(null);
  // const contactRef = React.useRef(null);

  return (
    <ThemeProvider theme={theme}>
      <DefaultStyles />
      <ResponsiveAppBar
        refs={{
          About: aboutRef,
          Skills: skillsRef,
          Works: worksRef,
          // Contact: contactRef,
        }}
        role={role}
        setRole={setRole}
      />

      <BackToTopBtn />
      <div className="mouse-stalker"></div>
      <Grid
        container
        component="main"
        sx={{
          mx: "auto",
          pt: "10vh",
          pb: 4,
          height: "fit-content",
          minHeight: { xs: "100vh" },
          width: "100vw",
          maxWidth: 1920,
          overflow: "hidden",
          position: "relative",
          bgcolor: "primary.main",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            maxHeight: "100vh",
            minHeight: {
              xs: "40vh",
              sm: "50vh",
              md: "min(90vh, 690px)",
            },
          }}
        >
          <Headline role={role} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            height: "fit-content",
            minHeight: { md: "min(90vh, 690px)" },
            // pt: "10vh",
            // px: 2,
            bgcolor: "primary.dark",
            overflow: "hidden",
          }}
          ref={aboutRef}
        >
          <Profile role={role} setRole={setRole} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            minHeight: { xs: "50vh", md: "min(90vh, 690px)" },
            maxHeight: { xs: "60vh", md: "100vh" },
            pt: "10vh",
          }}
          ref={skillsRef}
        >
          <Skills role={role} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            minHeight: "100vh",
            height: "fit-content",
            pt: "10vh",
            bgcolor: "primary.dark",
          }}
          ref={worksRef}
        >
          <Works role={role} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default App;
