import React, { useState } from "react";
import { gsap } from "gsap";
import "./module.style.css";

function MouseStalker({ hoverTarget, btnText }) {
  const [mouse, setMouse] = useState({ x: 0, y: 0 });

  const handleMouseOver = () => {
    gsap.to(".mouse_stalker", {
      opacity: 1,
      visibility: "visible",
      ease: "linear",
    });

    document.querySelector(".mouse_stalker").textContent = btnText;
  };

  const handleMouseLeave = () => {
    gsap.to(".mouse_stalker", {
      opacity: 0,
      visibility: "hidden",
      ease: "linear",
    });
  };

  const handleMouseMove = (event) => {
    const data = document
      .querySelector(".mouse_stalker")
      .getBoundingClientRect();
    const newX = event.clientX - data.width / 2;
    const newY = event.clientY - data.height / 2;

    setMouse({ x: newX, y: newY });
    gsap.to(".mouse_stalker", { x: newX, y: newY, ease: "Power1.easeOut" });
  };

  return (
    <div
      className="hover_target"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      {hoverTarget}
      <div className="mouse_stalker"></div>
    </div>
  );
}

export default MouseStalker;
