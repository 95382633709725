import * as React from "react";
import Box from "@mui/material/Box";

import { Typography, Fade, Grow } from "@mui/material";

export default function GrowBox(props) {
  // const [checked, setChecked] = React.useState(false);

  // const handleChange = () => {
  //   setChecked((prev) => !prev);
  // };

  return (
    <Box
      sx={{
        height: "fit-content",
        width: "fit-content",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        p: 0,
      }}
    >
      {/* <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} />}
        label="Grow Box"
        sx={{
          width: "100%",
          height: "fit-content",
          m: 0,
          p: 0,
        }}
      /> */}
      <Grow
        in={props.checked}
        easing={{ enter: "linear", exit: "ease-in-ease-out" }}
        style={{ transformOrigin: "center center" }}
        timeout={{ enter: 800, exit: 500 }}
        // unmountOnExit={true}: When the component is hidden, it is removed from the DOM completely. This means that the component is not in the DOM and does not take up any space. If you have a large number of hidden components or need to optimize rendering performance, unmountOnExit can be a useful tool. However, if your components are simple or frequently shown and hidden, it may not provide significant benefits and could even hurt performance.
        unmountOnExit
        sx={{
          height: "fit-content",
          width: "fit-content",
          maxWidth: "100%",
          maxHeight: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box>{props.children}</Box>
      </Grow>
    </Box>
  );
}
