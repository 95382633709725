import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#1D1F21",
      light: "#798185",
      dark: "#151516",
    },
    secondary: {
      main: "#E3E3E3",
      dark: "#a6a6a6",
      light: "#ffffff",
    },
    info: {
      main: "#2196f3",
    },
    background: {
      paper: "#E3E3E3",
      default: "#1D1F21",
    },
    text: {
      primary: "#1D1F21",
      secondary: "#E3E3E3",
    },
    success: {
      main: "#4caf50",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontFamily: "Montserrat",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "Open Sans",
      fontWeight: 700,
    },
    h3: {
      fontFamily: "Open Sans",
      fontWeight: 700,
    },
    caption: {
      fontFamily: "Roboto",
    },
    button: {
      fontFamily: "Oswald",
    },
    body2: {
      fontFamily: "Roboto",
    },
    body1: {
      fontFamily: "Roboto",
    },
    subtitle2: {
      fontFamily: "Roboto",
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: "Roboto",
      fontWeight: 600,
    },
    h4: {
      fontFamily: "Oswald",
      fontWeight: 500,
    },
    h5: {
      fontFamily: "Work Sans",
      fontWeight: 300,
    },
    h6: {
      fontFamily: "Work Sans",
      fontWeight: 300,
    },
    overline: {
      fontFamily: "Roboto",
    },
    fontFamily: "Montserrat",
    fontWeightLight: 300,
  },
  overrides: {
    MuiAppBar: {
      color: "secondary",
      colorPrimary: {
        backgroundColor: "red",
      },
    },
  },
});

export default theme;
